import { Tooltip } from "@mui/material";
import { ArrowLineRight } from "@phosphor-icons/react";
import style from "./style.module.css";
import { useEffect, useRef, useState } from "react";
import { Microphone, MicrophoneSlash } from "@phosphor-icons/react/dist/ssr";
import { Autorenew } from "@mui/icons-material";
import { useSelector } from "react-redux";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import { URL } from "../../../variables";
import React  from 'react';

export default function Info({
  show_info,
  setShowInfo,
  getAdminId,
  entrevistas,
  students, id_candidato,
  changeStudent

}) {
  const [student, setStudent] = useState({});
  const token = useSelector(store=>store.AppReducer.token);
  const processo = useSelector(store=>store.AppReducer.processo);


  const [show_warning, setShowWarning] = useState(false);
  const [first, setFirst] = useState(true);


  const [loading, setLoading] = useState(false);

  const [notas_criterios, setNotasCriterios] = useState({});
  const [notas, setNotas] = useState({});
  
  useEffect(() => {
    // let stude=students.filter((item) => item.id == id_candidato)[0];
    // setStudent(stude)

    // if(first==true){
    //   let notas={}
    //   stude?.exams_oral.grades.map((item)=>{
    //     notas[item.criterio_id]=item.grade;
    //   })
    //   setNotasCriterios(notas);
    //   setFirst(false);
    // }
  }, [students, id_candidato])
  useEffect(()=>{
    if(entrevistas[0]!=undefined){
      let notas={};
      entrevistas[0].criterios.map((item)=>{
        notas[item.id]=[]
        let nota = parseInt(item.min_score);
        while(nota<=parseInt(item.max_score)){
          notas[item.id].push(parseFloat(parseFloat(nota).toFixed(2)));
          nota+=0.25;
        }
      })
      setNotas(notas);
      
    }
  },[entrevistas])

  const saveGrades = (finish)=>{
    setLoading(true)
    fetch(`${URL}api/avaliador/save_grades`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            student_id: student.id,
            entrevista_id: entrevistas[0].id,
            processo_id: processo.id,
            finish,
            notas_criterios
        })
    }).then(async (responseLog) => {
        try {
            let resp = await responseLog.json();
            if (resp.errors != null || resp.error != null) {
                let errors = Object.values(resp.errors);
                let erro = '';
                for (let i = 0; i < errors.length; i++) {
                    if (i != errors.length - 1)
                        erro += errors[i] + '\n';
                    else
                        erro += errors[i];
                }
                console.log(erro);
                setLoading(false);
            }
            else {
              changeStudent(resp.candidato);
              setLoading(false);

            }




        } catch (err) {
            console.log(err);
            setLoading(false);

            // this.setState({ loading: false, msgErro: 'Erro ao pegar resposta do servidor' });
          
        }

    })
        .catch((err) => {
            console.log(err);
            setLoading(false);

            // this.setState({ loading: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
        });
  }
  console.log('student', student)
  return (
    <div
      className="Transition-1"
      style={{
        height: "100%",
        maxWidth: '50vw',
        width: "100%",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        backgroundColor: "var(--background-color0)",
        borderRadius: "1rem",
        padding: "1rem",
        position: "absolute",
        top: "0",
        right: show_info ? "0" : "-100vw",
      }}
    >
      {/* header */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingTop: "0.6rem",
        }}
      >
        <span
          style={{
            fontSize: "1.4rem",
            fontWeight: "bold",
          }}
        >
          Informações da avaliação
        </span>
        <Tooltip
          title={
            <span
              style={{
                fontSize: "1rem",
                textAlign: "center",
              }}
            >
              Esconder informações
            </span>
          }
          placement="bottom"
          arrow
          onClick={() => setShowInfo(false)}
        >
          <ArrowLineRight size={24} />
        </Tooltip>
      </div>

      {/* participants container */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          height: "100%",
          overflowY: "auto",
        }}
      >
        {entrevistas.map((item, id) => (

          <div key={id} style={{ marginTop: '1rem' }}>
            <h5 className="mt-0 header-title" style={{ fontSize: '1.5rem', textAlign: 'center' }}>
              {item.name}</h5>
            <p style={{ whiteSpace: 'pre-line' }}><b style={{ color: 'black', fontSize: 18 }}>Descrição:</b> <div dangerouslySetInnerHTML={{ __html: item.descricao }}></div></p>
            <div style={{ marginTop: 10 }}>
              {item.topicos.map((item2, id2) => (
                <div key={id2} style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}><div style={{ background: 'black', borderRadius: 100, minWidth: 6, minHeight: 6, marginRight: 5 }}></div> <span>{item2.text}</span></div>
              ))}
            </div>
            <p style={{ color: 'black', textAlign: 'center', marginBottom: 10 }}><b >Início:</b> {student?.exams_oral?.datetime_initial ? moment(student.exams_oral?.datetime_initial).format('DD/MM/YYYY HH:mm') : '-'}</p>
            <p style={{ color: 'black', textAlign: 'center', marginBottom: 10 }}><b >Fim:</b> {student?.exams_oral?.datetime_end ? moment(student.exams_oral?.datetime_end).format('DD/MM/YYYY HH:mm') : '-'}</p>
            <p style={{}}><b style={{ color: 'black', fontSize: 18, marginTop: 10 }}>Critérios:</b> </p>

            <div className="table-rep-plugin">
              <div className="table-responsive mb-0" data-pattern="priority-columns">
                <table className="table table-striped">
                  <thead>
                    <tr>

                      <th>Código do Critério</th>
                      <th>Texto do Critério</th>
                      <th>Range</th>
                      <th>Nota</th>
                    </tr>

                  </thead>
                  <tbody >

                    {item.criterios.map((item2, id) => (
                      <tr key={id}>
                        <td>
                          {item2.code}

                        </td>
                        <td>
                          {item2.text}

                        </td>
                        <td>
                          {item2.min_score} - {item2.max_score}

                        </td>
                        <td>
                          <select value={notas_criterios[item2.id] ?? 0} className="custom-select" id="cameras" onChange={(e) => {
                            let notas = {...notas_criterios}
                            notas[item2.id]=e.target.value;
                            setNotasCriterios(notas);
                          }}>
                            {notas[item2.id]?.map((item3,id3)=>(
                            <option key={id3} value={item3}>{item3}</option>
                            ))}
                          </select>

                        </td>
                      </tr>
                    ))}





                  </tbody>
                </table>
              </div>
            </div>
            <p style={{ color: 'black',  marginBottom: 10 }}><b >Nota final:</b> {Object.values(notas_criterios).reduce((accumulator, currentValue) => {
  return parseFloat(accumulator) + parseFloat(currentValue)
},0)}</p>


            <hr />
            <div style={{display:'flex',justifyContent:'flex-end'}}>
            {loading==false && <button type="button"  className="btn btn-success btn-lg btn-outline btn-circle m-r-5" onClick={()=>{
              saveGrades(false);
            }} >Salvar notas <i
            className="mdi mdi-check"
            
            style={{  color: 'white', fontStyle: 'normal' }}
            >
                </i></button>}
                {loading==false && student?.exams_oral?.finish==false && <button type="button"  className="btn btn-danger btn-lg btn-outline btn-circle m-r-5"
                onClick={()=>{
                  setShowWarning(true);
                }}
                >Salvar e encerrar avaliação <i
            className="mdi mdi-check"
            style={{  color: 'white', fontStyle: 'normal' }}
            >
                </i></button>}

            {loading==true &&<div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>}
              </div>
          </div>
        ))}

<SweetAlert
                        warning
                        title={'Atenção'}
                        onConfirm={()=>{
                          setShowWarning(false);
                          saveGrades(true);
                        }}
                        onCancel={()=>{
                          setShowWarning(false);
                        }}
                        show={show_warning}
                        confirmBtnText='Sim, desejo finalizar'
                        cancelBtnText='Cancelar'
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        showCancel={true}
                    >
                        Ao finalizar a avaliação do candidato, ele será retirado da vídeo chamada e não poderá mais acessá-la
                    </SweetAlert>

      </div>
    </div>
  );
}

