import VideoAreaParticipantItem from "./ParticipantItem";
import { useEffect, useState } from "react";
import VideoAreaBottomControlls from "./BottomControlls";
import React  from 'react';

export default function VideoArea({
  participants,
  focused_participant,
  setFocusedParticipant,
  playVideo,
  getAdminId,

  // streaming control
  localStream,
  localStreamScreen,
  enableVideoStream,
  handleEnableVideoStream,
  enableAudioStream,
  handleEnableAudioStream,
  // share screen control
  localScreen,
  isShareScreen,
  handleStartScreenShare,
  handleDisconnectScreenShare,

  handleDisconnect,
  messageWarning,
  // right content control
  show_info,
  setShowInfo,
  show_chat,
  setShowChat,
  show_participants,
  setShowParticipants,
  get_messages_from_student,
  students,
  id_candidato

}) {
  const [force_row_direction, setForceRowDirection] = useState(false);

  useEffect(() => {
    // row direction with 2 participants because it's better to see
    if (Object.keys(participants).length === 2 && focused_participant==null) {
      setForceRowDirection(true);
    } else {
      setForceRowDirection(false);
    }
  }, [participants]);
  console.log('focused part',focused_participant)
  console.log(focused_participant? Object.keys(focused_participant).filter((item)=>item!='type'):{})
  
  return (
    <div
      style={{
        height: "100%",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        padding: "1rem",
      }}
    >
    
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: force_row_direction ? "row" : "column",
          flexWrap: "wrap",
          gap: "1rem",
          overflowY: "hidden",
          // width:'100px'
        }}
      >
        {Object.keys(participants).map((key, index) => {
          let row_grid = 0;

          if(focused_participant==null || (focused_participant[participants[key].id]!=undefined)){
          const participants_count = Object.keys(participants).length;

          if (participants_count % 2 === 0) {
            row_grid = participants_count;
          } else {
            row_grid = participants_count - 1;
          }
          console.log('focused',focused_participant)
          return (
            participants[key].screen==true && participants[key].video_cam==true?
            <>
            {<VideoAreaParticipantItem
              key={index}
              type='screen'
              {...{
                getAdminId,
                row_grid,
                participant: participants[key],
                participants,
                focused_participant,
                setFocusedParticipant,
                playVideo,
                localScreen,
                localStreamScreen,
                isShareScreen,
                index,
                participants_count,
                show_info,
                setShowInfo,
              }}
            />}
            {<VideoAreaParticipantItem
              key={index+'_video'}
              type='video'
              {...{
                getAdminId,
                row_grid,
                participant: participants[key],
                participants,
                focused_participant,
                setFocusedParticipant,
                playVideo,
                localScreen,
                localStreamScreen,
                isShareScreen,
                index,
                participants_count,
                show_info,
                setShowInfo,
              }}
            />}
            </>:<>
            <VideoAreaParticipantItem
              key={index}
              type={participants[key].screen==true?'screen': 'video'}

              {...{
                getAdminId,
                row_grid,
                participant: participants[key],
                participants,
                focused_participant,
                setFocusedParticipant,
                playVideo,
                localScreen,
                localStreamScreen,
                isShareScreen,
                index,
                participants_count,
                show_info,
                setShowInfo,
              }}
            />
            </>
            
          );
        }
        })}
    </div>

      {messageWarning!='' &&  <p className={messageWarning.includes("atenção")?'blink_me':''} style={{fontSize:16,color:messageWarning.includes("atenção")?'': 'red',textAlign:'center'}}>{messageWarning}</p>}
      <VideoAreaBottomControlls
        {...{
          // streaming control
          localStream,
          enableVideoStream,
          handleEnableVideoStream,
          enableAudioStream,
          handleEnableAudioStream,
          isShareScreen,
          handleStartScreenShare,
          handleDisconnectScreenShare,
          handleDisconnect,
          students:students,
          id_candidato,
          show_info,
          setShowInfo,
          // right content control
          show_chat,
          setShowChat,
          show_participants,
          setShowParticipants,
          get_messages_from_student:get_messages_from_student

        }}
      />
    </div>
  );
}
