import React  from 'react';
import { Tooltip } from "@mui/material";
import { ArrowLineRight } from "@phosphor-icons/react";
import style from "./style.module.css";
import { useEffect, useRef } from "react";
import { Microphone,MicrophoneSlash } from "@phosphor-icons/react/dist/ssr";
import { Autorenew } from "@mui/icons-material";

export default function ParticipantsRoom({
  participants,
  show_participants,
  setShowParticipants,
  focused_participant,
  setFocusedParticipant,
  playVideo,
  getAdminId,
  changeVideo
}) {
  return (
    <div
      className="Transition-1"
      style={{
        height: "100%",
        width: "100%",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        backgroundColor: "var(--background-color0)",
        borderRadius: "1rem",
        padding: "1rem",
        position: "absolute",
        top: "0",
        right: show_participants ? "0" : "-100vw",
      }}
    >
      {/* header */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingTop: "0.6rem",
        }}
      >
        <span
          style={{
            fontSize: "1.4rem",
            fontWeight: "bold",
          }}
        >
          Participantes
        </span>
        <Tooltip
          title={
            <span
              style={{
                fontSize: "1rem",
                textAlign: "center",
              }}
            >
              Esconder participantes
            </span>
          }
          placement="bottom"
          arrow
          onClick={() => setShowParticipants(false)}
        >
          <ArrowLineRight size={24} />
        </Tooltip>
      </div>

      {/* participants container */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          height: "100%",
          overflowY: "auto",
        }}
      >
        {Object.keys(participants).map((key, index) => (
          <ParticipantItem
            key={index}
            {...{
              index,
              changeVideo,
              getAdminId:getAdminId,

              participant: participants[key],
              playVideo,
            }}
            onClick={() => {
              if (focused_participant === null) {
                console.log(
                  new Map([
                    [participants[key].id, participants[key]],
                  ])
                );
                console.log(participants[key])
                setFocusedParticipant({
                  [participants[key].id]: participants[key],
                  type:participants[key].screen==true?'screen':'video',
                });
              } else {
                setFocusedParticipant(null);
              }
            }}
          />
        ))}
      </div>
    </div>
  );
}

function ParticipantItem({ participant, playVideo, onClick, index,getAdminId,changeVideo }) {
  // const remoteVideo = useRef();

  // useEffect(() => {
  //   playVideo(remoteVideo.current, participant.stream.stream)
  //     ?.then(() => {
  //       remoteVideo.current.volume = 1;
  //     })
  //     .catch((err) => {
  //       console.error("media ERROR:", err);
  //     });
  // }, []);
  const audio_ref = useRef();
  console.log('participant',participant)
  useEffect(()=>{
    if(participant.audio_track!=null && participant.id!=getAdminId()){
      let stream_audio=new MediaStream();
      stream_audio.addTrack(participant.stream.getAudioTracks()[0]);
      audio_ref.current.srcObject=stream_audio;
      console.log('adicionou audio')
      audio_ref.current.play();
    }
  },[participant.audio_track])
  return (
    <div className={style.participantContainer} onClick={onClick}>
      {/* <video
        ref={remoteVideo}
        autoPlay
        style={{
          display: "block",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          height: "100%",
          objectFit: "cover",
          zIndex: 2,
        }}
      ></video> */}
      <audio ref={audio_ref}></audio>
      <div style={{
          width: 58,
          height: 58,
          borderRadius: "50%",
          objectFit: "cover",
          display:'flex',
          justifyContent:'center',
          alignItems:'center',
          background:participant.id==getAdminId()?'orange': 'purple',
          minWidth: 58,
          minHeight: 58,
        }}>
          <p style={{marginBottom:0,textAlign:'center',color:'white',fontSize:22}}>{participant.name.toUpperCase().substring(0,2)}</p>
        </div>
      {/* {<img
        src={"https://picsum.photos/200" + index}
        alt=""
        style={{ aspectRatio: "1/1", height: "3.6rem", borderRadius: "50%" }}
      />} */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          flexDirection: "column",
          marginRight:40
        }}
      >
        <span
          style={{
            fontSize: "1rem",
            fontWeight: "bold",
          }}
        >
          {participant.name}
        </span>
        <span
          style={{
            fontSize: "1rem",
            // fontWeight: "bold",
          }}
        >
          {participant.type}
        </span>
        {/* {participant.video_cam==true && participant.screen==true && <span
          style={{
            fontSize: "0.9rem",
            display: "flex",
            alignItems: "center",
            cursor:'pointer'
          }}
          onClick={(e)=>{
            e.stopPropagation();
            changeVideo(participant.video_show=='camera'?'screen':'camera',participant.id)
          }}
        >
          <Autorenew />
          {participant.video_show=='camera'?'Visualizar tela compartilhada':'Visualizar câmera'}
        </span>} */}
      </div>

      <div style={{display:'flex',justifyContent:'center',alignItems:'center',position:'absolute',right:10,top:'38%'}}>
        {participant.audio==false && <MicrophoneSlash size={25} color="grey" />}
      </div>
    </div>
  );
}
