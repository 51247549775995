import moment from "moment";
import React  from 'react';

export default function ChatRoomMessageItem({
  sender,
  message,
  has_media,
  media,
  isMine = false,
  created_at,
  visto
}) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: isMine ? "flex-end" : "flex-start",
        alignItems: "flex-end",
        gap: "0.6rem",
      }}
    >
      {!isMine && (
        <div style={{
          width: "3rem",
          height: "3rem",
          borderRadius: "50%",
          objectFit: "cover",
          display:'flex',
          justifyContent:'center',
          alignItems:'center',
          background:'purple',
          minWidth: "3rem",
          minHeight: "3rem",
        }}>
          <p style={{marginBottom:0,textAlign:'center',color:'white'}}>{sender.name.toUpperCase().substring(0,2)}</p>
        </div>
        // <img
        //   src={user.avatar_path}
        //   alt={`Avatar de ${sender.name}`}
        //   style={{
        //     width: "3rem",
        //     height: "3rem",
        //     borderRadius: "50%",
        //     objectFit: "cover",
        //   }}
        // />
      )}

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "0.4rem",
          backgroundColor: !isMine
            ? "var(--background-color1)"
            : "rgb(var(--accent-color0), 0.2)",
          padding: "0.8rem 1rem",
          borderRadius: isMine ? "1rem 1rem 0 1rem" : "1rem 1rem 1rem 0",
          margin: isMine ? "0 0 0 1rem" : "0 1rem 0 0",
          boxShadow: "rgba(0, 0, 0, 0.1) 0px 2px 2px 0px",
        }}
      >
        {!isMine && (
          <span
            style={{
              fontSize: "1rem",
              fontWeight: "bold",
            }}
          >
            {sender.name}
          </span>
        )}
        <div
          style={{
            fontSize: "1rem",
            lineHeight: "1.2rem",
            textAlign: isMine ? "right" : "left",
          }}
        >
          {message}
        </div>
        {isMine?<div style={{display:'flex',justifyContent:'flex-end',alignItems:'center'}}>
                                                        <span style={{ fontSize: 10, marginRight: 2 }}>{moment(created_at).tz("America/Sao_Paulo").format('DD/MM/YYYY HH:mm')}</span>
                                                        {visto == undefined && <i className="fas fa-clock" style={{ fontSize: '0.5rem', marginLeft: '1rem', padding: 0, margin: 0 }}></i>}
                                                        {visto == false && <i className="fas fa-check" style={{ fontSize: '0.5rem', marginLeft: '1rem', padding: 0, margin: 0 }}></i>}
                                                        {visto == true && <i className="fas fa-check-double" style={{ fontSize: '0.5rem', marginLeft: '1rem', padding: 0, margin: 0 }}></i>}
                                                    </div>: <div style={{ display: 'flex',justifyContent:'flex-end',alignItems:'center' }}>
          <p style={{ fontSize: 13, marginBottom: 0, fontSize: 10, marginRight: 3, marginLeft: '0.5rem' }}>{moment(created_at).tz("America/Sao_Paulo").format('DD/MM/YYYY HH:mm')}</p>
      </div>}

        {has_media && (
          <div
            style={{
              marginTop: "0.6rem",
              width: "100%",
              height: "auto",
              borderRadius: "0.6rem",
              overflow: "hidden",
            }}
          >
            {media.type === "image" && (
              <img
                src={media.path}
                alt=""
                style={{
                  width: "100%",
                  height: "auto",
                  objectFit: "cover",
                }}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}
