
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { logout, mudarMensagens, mudarChatOpen } from '../actions/AppActions';
import { connect } from 'react-redux';

import moment from 'moment-timezone';
import { URL } from '../variables';

class ChatIndividual extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menu: false,
            redirect: false,
            path: '/',
            texto: '',
            chat_open: -1,
            alunos: {},
            aluno_select: { mensagens: [],requests:[] },
            comunicados: [],
            name_filter: '', tab: 0
        };
        this.messages_view = React.createRef();
        this.requests_view = React.createRef();

    }

    componentDidUpdate(props) {
        if (this.props.aluno_select.id != this.state.aluno_select.id || this.props.aluno_select.sala_id != this.state.aluno_select.sala_id) {
            this.setState({ aluno_select: this.props.aluno_select,tab:0 }, () => {
                this.messages_view.current.scrollTop = this.messages_view.current.scrollHeight;
        this.requests_view.current.scrollTop = this.requests_view.current.scrollHeight;

            });
        }

        if (this.props.chat_open != props.chat_open) {
            this.setState({tab:this.props.chat_open==-1?0:this.props.chat_open})
            this.messages_view.current.scrollTop = this.messages_view.current.scrollHeight;
            this.requests_view.current.scrollTop = this.requests_view.current.scrollHeight;
        }
    }



    componentDidMount() {

        this.messages_view.current.scrollTop = this.messages_view.current.scrollHeight;
        this.requests_view.current.scrollTop = this.requests_view.current.scrollHeight;


        this.props.socket.on("send_msg_fiscal", function (data) {
            console.log(data);
            if (data.admin_id != this.admin_id) {
                if (this.state.aluno_select.id == data.aluno_id) {
                    setTimeout(() => {
                        this.messages_view.current.scrollTop = this.messages_view.current.scrollHeight;
                    }, 100);
                }
            }
        }.bind(this));

        this.props.socket.on("request_pause", function (data) {
            if (data.admin_id != this.admin_id) {
                if (this.state.aluno_select.id == data.aluno_id) {
                    setTimeout(() => {
                        this.requests_view.current.scrollTop = this.requests_view.current.scrollHeight;
                    }, 100);
                }
            }
        }.bind(this));

        // let aluno_select={};
        // if(this.props.user.role=='fiscal_coordenador'){
        //     aluno_select =  { id: 'comunicado', name: 'Comunicado Geral' };
        // }
        // else{
        //     if(Object.values(this.props.alunos).length>0){
        //         aluno_select =  Object.values(this.props.alunos)[0];
        //     }
        // }
        // this.setState({ aluno_select });
        this.admin_id = this.props.admin_id;
        // this.props.socket.on("send_msg_fiscal", function (data) {
        //     if (data.admin_id != this.admin_id) {
        //         let alunos = { ...this.props.alunos };
        //         alunos[data.aluno_id].mensagens.push({
        //             message: data.msg,
        //             sala_id: data.sala_id,
        //             sender: { id: data.aluno_id, name: data.nome_chat },
        //             sender_id: data.aluno_id,
        //             uuid: data.id_msg,
        //             warning: false
        //         });
        //         alunos[data.aluno_id].order = moment().unix();
        //         // this.props.mudarState({ alunos });
        //         if (this.state.aluno_select.id == data.aluno_id) {


        //             alunos[data.aluno_id].counter = 0;
        //             if (this.messages_view.current.scrollTop + parseInt(this.messages_view.current.style.height.replace('px', '')) == this.messages_view.current.scrollHeight) {
        //                 setTimeout(() => {
        //                     this.messages_view.current.scrollTop = this.messages_view.current.scrollHeight;
        //                 }, 100);
        //             }
        //             this.props.mudarState({ alunos });
        //             this.props.socket.emit('msg_receive_aluno', { sala_id: data.sala_id, aluno_id: data.aluno_id, uuid: data.id_msg, faculdade_id: this.props.faculdade.id });


        //         }

        //         else {
        //             alunos[data.aluno_id].counter += 1;
        //             this.props.mudarState({ alunos });
        //         }

        //     }
        // }.bind(this));

        // this.props.socket.on("send_warning_behavior_fiscal", function (data) {
        //     if (data.admin_id != this.admin_id) {

        //         let alunos = { ...this.props.alunos };
        //         alunos[data.aluno_id].mensagens.push({
        //             message: data.msg,
        //             sala_id: data.sala_id,
        //             sender: { id: data.aluno_id, name: data.nome_chat },
        //             sender_id: data.aluno_id,
        //             uuid: data.id_msg,
        //             warning: true
        //         });
        //         alunos[data.aluno_id].order = moment().unix();

        //         // $('#msg_count
        //         // $('#msg_count' + data.aluno_id).addClass('msg_count_warning');
        //         if (this.state.aluno_select.id == data.aluno_id) {
        //             alunos[data.aluno_id].counter = 0;

        //             if (this.messages_view.current.scrollTop + parseInt(this.messages_view.current.style.height.replace('px', '')) == this.messages_view.current.scrollHeight) {

        //                 setTimeout(() => {
        //                     this.messages_view.current.scrollTop = this.messages_view.current.scrollHeight;
        //                 }, 200);
        //             }
        //             this.props.mudarState({ alunos });
        //             this.props.socket.emit('msg_receive_aluno', { sala_id: data.sala_id, aluno_id: data.aluno_id, uuid: data.id_msg, faculdade_id: this.props.faculdade.id, warning: true });


        //         }
        //         else {
        //             alunos[data.aluno_id].counter += 1;
        //             this.props.mudarState({ alunos });
        //         }
        //     }
        // }.bind(this));


        // this.props.socket.on("msg_receive" + this.admin_id, function (data) {
        //     // $('#' + data.id_msg).removeClass();
        //     // $('#' + data.id_msg).addClass('fas fa-check-double');
        //     let alunos = { ...this.props.alunos };
        //     for (let i = 0; i < alunos[data.aluno_id].mensagens.length; i++) {
        //         if (alunos[data.aluno_id].mensagens[i].uuid == data.id_msg) {
        //             alunos[data.aluno_id].mensagens[i].visto = true;
        //             break;
        //         }
        //     }
        //     this.props.mudarState({ alunos });
        // }.bind(this));

        // this.props.socket.on("msg_save" + this.admin_id, function (data) {
        //     // $('#' + data.id_msg).removeClass();
        //     let alunos = { ...this.props.alunos };
        //     for (let i = 0; i < alunos[data.aluno_id].mensagens.length; i++) {
        //         if (alunos[data.aluno_id].mensagens[i].uuid == data.id_msg) {
        //             alunos[data.aluno_id].mensagens[i].visto = false;
        //             break;
        //         }
        //     }
        //     this.props.mudarState({ alunos });
        //     // $('#' + data.id_msg).addClass('fas fa-check');
        // }.bind(this));


        // this.props.socket.on("finish_event", function (data) {
        //     // $('#' + data.id_msg).removeClass();
        //     console.log(data);
        //     // let alunos = { ...this.props.alunos };
        //     // for (let i = 0; i < alunos[data.aluno_id].mensagens.length; i++) {
        //     //     if (alunos[data.aluno_id].mensagens[i].uuid == data.id_msg) {
        //     //         alunos[data.aluno_id].mensagens[i].visto = false;
        //     //         break;
        //     //     }
        //     // }
        //     // this.props.mudarState({ alunos });
        //     // $('#' + data.id_msg).addClass('fas fa-check');
        // }.bind(this));
    }

    componentWillUnmount() {
        clearTimeout(this.logs_timeout);
    }

    receive_all(uuid, sala_id, aluno_id) {
        fetch(`${URL}api/fiscal/receive_all_fiscal`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                uuid,
                sala_id, aluno_id
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                else {

                }
            } catch (err) {
                console.log(err);
            }

        })
            .catch((err) => {
                console.log(err);
            });
    }




    uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    

    send_comunicado() {
        let msg = this.state.texto;

        if (msg != null && msg != '' && msg.replace(/ /g, '') != '') {
            if (this.state.aluno_select.id == 'comunicado' && this.state.aluno_select.sala_id == undefined) {
                let id_msg = this.uuidv4();
                this.props.socket.emit("comunicado_geral", { id_msg, nome_chat: 'Comunicado Geral', msg: this.state.texto, id_adm: this.props.user.id, faculdade_id: this.props.faculdade.id });
                let comunicados = [...this.props.comunicados];
                comunicados.push({
                    message: msg,
                    id_msg,
                    created_at: moment().format('YYYY-MM-DD HH:mm:ss')

                });
                this.props.mudarState({ comunicados });
                this.setState({ texto: '' }, () => {
                    this.messages_view.current.scrollTop = this.messages_view.current.scrollHeight;
                });
            }
            else if (this.state.aluno_select.id == 'comunicado' && this.state.aluno_select.sala_id != undefined) {
                let id_msg = this.uuidv4();

                this.props.socket.emit("comunicado_sala", { id_msg, sala_id: this.state.aluno_select.sala_id, nome_chat: 'Mensagem para Sala ' + this.state.aluno_select.numero, msg: this.state.texto, id_adm: this.props.user.id, faculdade_id: this.props.faculdade.id });
                let comunicados = [...this.props.comunicados];
                comunicados.push({
                    message: msg,
                    id_msg,
                    sala_id: this.state.aluno_select.sala_id,
                    created_at: moment().format('YYYY-MM-DD HH:mm:ss')

                });
                this.props.mudarState({ comunicados });
                this.setState({ texto: '' }, () => {
                    this.messages_view.current.scrollTop = this.messages_view.current.scrollHeight;
                });
            }

        }
    }



    render() {
        // let alunos = Object.values(this.props.alunos);
        // alunos = alunos.sort(function (a, b) { return b.order - a.order })
        // let online = this.props.online;
        // console.log(this.state.aluno_select);
        return (
            <div
                style={{
                    background: 'white', height: '100vh', width: '100%',
                    bottom: this.state.chat_open == -1 ? '-500px' : 0,
                    right: '15px',
                    borderRadius: '5px',
                    wordBreak: 'break-word',
                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
                }} id="chat_father">


                <div className="row">
                    {/* <div className="col-4" style={{ borderRight: '1px solid grey', paddingRight: 0, overflowY: 'scroll', height: '600px' }}>
                        <div style={{ width: '100%' }}>
                            <div style={{position:'relative',padding:10,height:60}}>
                            <div style={{position:'absolute',top:21,left:20}}>
                                <i className="fas fa-search" style={{fontSize:'1rem'}}></i>
                            </div>
                            <input autoComplete="off"  onChange={(e) => this.setState({ name_filter: e.target.value })} value={this.state.name_filter} name="msg" placeholder="Buscar candidato..." className="form-control msg_input" style={{ resize: 'none', height: '100%',borderRadius:50,paddingLeft:40 }} />
                            </div>

                            {this.props.user.role=='fiscal_coordenador' && <div onClick={() => {
                                this.setState({ aluno_select: { id: 'comunicado', name: 'Comunicado Geral' }, texto: '' });
                            }} style={{ padding: 10, borderBottom: '1px solid rgba(0,0,0,.1)', cursor: 'pointer', background: '#38a4f8', borderTopLeftRadius: '5px' }}>
                                <h6 style={{ color: 'black' }}>Comunicado geral</h6>
                                {this.state.comunicados.length > 0 && <div>
                                    <span style={{ color: 'white' }}>{this.state.comunicados[this.state.comunicados.length - 1].message}</span>
                                </div>}
                            </div>}
                            {alunos.map((aluno, id2) => (
                                <div hidden={!aluno.name.toLowerCase().includes(this.state.name_filter.toLowerCase()) && !aluno.sala.toLowerCase().includes(this.state.name_filter.toLowerCase())  && this.state.name_filter!=''} onClick={() => {
                                    if (aluno.mensagens.length > 0) {
                                        this.props.socket.emit('msg_receive_aluno', { sala_id: aluno.sala_id, aluno_id: aluno.id, uuid: aluno.mensagens[aluno.mensagens.length - 1].uuid, faculdade_id: this.props.faculdade.id });
                                        this.receive_all(aluno.mensagens[aluno.mensagens.length - 1].uuid, aluno.sala_id, aluno.id);
                                    }
                                    let alunos = { ...this.props.alunos };
                                    alunos[aluno.id].counter = 0;
                                    this.setState({ aluno_select: { ...aluno, indice: id2 }, texto: '', alunos });
                                    setTimeout(()=>{
                                        this.messages_view.current.scrollTop = this.messages_view.current.scrollHeight;
                                    },100);
                                }} key={id2} style={{ display: 'flex', justifyContent: 'space-between', padding: 10, borderBottom: '1px solid rgba(0,0,0,.1)', cursor: 'pointer', background: this.state.aluno_select.id == aluno.id ? '#f1f1f1' : 'white', borderTopLeftRadius: 0 }}>
                                    <div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <h6 style={{ color: 'black' }}>{aluno.name} - {aluno.sala}</h6>
                                            <div></div>
                                        </div>
                                        {aluno.mensagens.length > 0 && <div>
                                            <span style={aluno.counter > 0 ? { fontWeight: 'bold', color: 'black' } : {}}>{aluno.mensagens[aluno.mensagens.length - 1].message}</span>
                                        </div>}
                                    </div>

                                    <div style={{ alignItems: 'flex-end', display: 'inherit',position:'relative' }}>
                                        {aluno.counter > 0 && <div style={{
                                            padding: '8px',
                                            paddingTop: '3px',
                                            background: 'rgb(75, 181, 67)',
                                            borderRadius: '50px',
                                            position: 'absolute',
                                            top: 0,
                                            textAlign: 'center',
                                            paddingBottom: '3px',
                                            right: '10px'
                                        }}>
                                            <span style={{ color: 'white', textAlign: 'center' }}>{aluno.counter}</span>
                                        </div>}
                                        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                                            {online.includes(aluno.id.toString()) && <div style={{ padding: 5, width: 55, height: 31, }}>
                                                <span style={{ color: '#4BB543', textAlign: 'center' }}>Online</span>
                                            </div>}
                                            {!online.includes(aluno.id.toString()) && <div style={{ padding: 5, width: 55, height: 31, }}>
                                                <span style={{ color: 'indianred', textAlign: 'center' }}>Offline</span>
                                            </div>}
                                        </div>
                                    </div>


                                </div>
                            ))}
                        </div>
                    </div> */}
                    <div className="col-12">
                        <div style={{
                            boxShadow: "rgba(0, 0, 0, 0.2) 0px 4px 8px 0px, rgba(0, 0, 0, 0.19) 0px 6px 20px 0px"
                        }}>

                        <a id="close_chat"

                            style={{ borderTopLeftRadius: '5px', borderTopRightRadius: '5px', backgroundColor: '#f2f2f2', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>

                            <p style={{ margin: 0,fontSize:14,fontWeight:'bold', color: 'black', textAlign: 'center' }}>{this.state.aluno_select.name}</p>
                            <button type="button" className="close" data-dismiss="alert" aria-label="Close" style={{
                                position: 'absolute',
                                left: '10px',
                                // color: 'white',
                                fontSize: '2rem'
                            }}
                                onClick={() => {
                                    this.props.mudarChatOpen(-1);
                                }}
                            >
                                <span aria-hidden="true"><i className="mdi mdi-close"></i></span>
                            </button>

                        </a>
                        {this.state.aluno_select.id != 'comunicado' && <div style={{ display: 'flex', background: `#f2f2f2`, height: 40 }}>
                            <div onClick={() => {
                                this.setState({ tab: 0 },()=>{
                                this.messages_view.current.scrollTop = this.messages_view.current.scrollHeight;

                                    this.props.sendReceive();
                                });
                            }} style={{ cursor: 'pointer', borderBottom: this.state.tab == 0 ? '2px solid #17a2b8' : 0, width: '50%', padding: 5 }}>
                                <p style={{ marginBottom: 0, fontSize: '14px', textAlign: 'center', color: this.state.tab == 0 ? '#17a2b8' : 'black', }}><i
                                    className="mdi mdi-chat"
                                    style={{ fontSize: '14px', textAlign: 'center', color: this.state.tab == 0 ? '#17a2b8' : 'black', fontStyle: 'normal' }}>
                                </i>MENSAGENS</p>
                            </div>
                            <div onClick={() => {

                                this.setState({ tab: 1 },()=>{
                                this.requests_view.current.scrollTop = this.requests_view.current.scrollHeight;

                                });
                            }} style={{ cursor: 'pointer', borderBottom: this.state.tab == 1 ? '2px solid #17a2b8' : 0, width: '50%', padding: 5 }}>
                                <p style={{ marginBottom: 0, fontSize: '14px', textAlign: 'center', color: this.state.tab == 1 ? '#17a2b8' : 'black', }}><i
                                    className="mdi mdi-pause-circle-outline"
                                    style={{ fontSize: '14px', textAlign: 'center', color: this.state.tab == 1 ? '#17a2b8' : 'black', fontStyle: 'normal' }}>
                                </i> SOLICITAÇÕES</p>
                            </div>

                        </div>}
                        </div>

                        <div hidden={this.state.tab != 0}>

                            <div ref={this.messages_view} style={{ height: window.innerHeight - 40 - 70 - (this.state.aluno_select.id != 'comunicado'?40:0), padding: 0, margin: 0 }} className="overflow-auto hide-scroll" id="body_msg">
                                {this.state.aluno_select.id == 'comunicado' && this.props.comunicados.filter((item, id) => {
                                    if (this.state.aluno_select.sala_id == undefined && item.sala_id == null) {
                                        return true;
                                    }
                                    else {
                                        if (this.state.aluno_select.sala_id == item.sala_id) {
                                            return true;
                                        }
                                        else {
                                            return false;
                                        }
                                    }
                                }).map((item, id) => (
                                    <div key={id} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '0.5rem' }}>
                                        <div style={{ padding: '5px', backgroundColor: this.state.aluno_select.sala_id != undefined ? '#f2f2f2' : '#f2f2f2', borderRadius: '5px',  width:'100%' }} >
                                            <p style={{ marginBottom: 0,  fontWeight: 'bold', fontSize: '0.9rem' }} className={this.state.aluno_select.sala_id != undefined ?'text-success': 'text-info'}>{this.state.aluno_select.sala_id != undefined ? 'Mensagem para sala':'Comunicado Geral'}</p>
                                            <p style={{ marginBottom: 0, color: 'black',fontSize:14 }}>{item.message}</p>
                                            <div style={{display:'flex',justifyContent:'flex-end',alignItems:'center'}}>
                                                <span style={{ fontSize: 10, marginRight: 2, color: 'black' }}>{moment(item.created_at).tz("America/Sao_Paulo").format('DD/MM/YYYY HH:mm')}</span>
                                                {item.send == undefined && <i className="fas fa-clock" style={{ fontSize: '0.5rem', marginLeft: '1rem', padding: 0, margin: 0, color: 'black' }}></i>}
                                                {item.send == true && <i className="fas fa-check" style={{ fontSize: '0.5rem', marginLeft: '1rem', padding: 0, margin: 0, color: 'black' }}></i>}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                {this.state.aluno_select.id != undefined && this.state.aluno_select.id != 'comunicado' && this.state.aluno_select.mensagens.map((item, id) => {
                                    if (item.warning == true) {
                                        // return (
                                        //     <div key={id} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '0.5rem', marginBottom: '0.5rem' }}>
                                        //         <div style={{ padding: '5px', backgroundColor: 'lightyellow', marginRight: '0.5rem', borderRadius: '5px', textAlign: 'center', display: 'flex', alignItems: 'flex-end' }} >
                                        //             <p style={{ marginBottom: 0, color: 'black', marginLeft: '1rem', marginRight: '1rem', textAlign: 'center', textAlign: 'center' }}>{item.message}</p>
                                        //         </div>
                                        //     </div>
                                        // )
                                        return;
                                    }
                                    else if (item.receive_id == this.state.aluno_select.id && item.sender_id == this.props.user.id) {
                                        return (
                                            <div key={id} style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', marginTop: '0.5rem', marginBottom: '0.5rem' }}>
                                                <div style={{ padding: '5px', backgroundColor: 'aquamarine', marginRight: '0.5rem', marginLeft: '3rem', borderRadius: '5px', textAlign: 'justify' }} >
                                                    <p style={{ marginBottom: 0, color: 'black', marginRight: '1rem',fontSize:14 }}>{item.message}</p>
                                                    <div style={{display:'flex',justifyContent:'flex-end',alignItems:'center'}}>
                                                        <span style={{ fontSize: 10, marginRight: 2 }}>{moment(item.created_at).tz("America/Sao_Paulo").format('DD/MM/YYYY HH:mm')}</span>
                                                        {item.visto == undefined && <i className="fas fa-clock" style={{ fontSize: '0.5rem', marginLeft: '1rem', padding: 0, margin: 0 }}></i>}
                                                        {item.visto == false && <i className="fas fa-check" style={{ fontSize: '0.5rem', marginLeft: '1rem', padding: 0, margin: 0 }}></i>}
                                                        {item.visto == true && <i className="fas fa-check-double" style={{ fontSize: '0.5rem', marginLeft: '1rem', padding: 0, margin: 0 }}></i>}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    else if (item.sender_id == this.state.aluno_select.id) {
                                        return (
                                            <div key={id} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', marginTop: '0.5rem', marginBottom: '0.5rem' }}>
                                                <div style={{ padding: '5px', backgroundColor: 'azure', marginRight: '3rem', marginLeft: '0.5rem', borderRadius: '5px', textAlign: 'justify' }}>
                                                    <p style={{ marginBottom: 0, color: 'blue', fontWeight: 'bold', fontSize: '0.9rem' }}>{item.sender.name}</p>
                                                    <p style={{ marginBottom: 0, color: 'black',fontSize:14 }}>{item.message}</p>
                                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                        <p style={{ fontSize: 13, marginBottom: 0, fontSize: 10, }}>{moment(item.created_at).tz("America/Sao_Paulo").format('DD/MM/YYYY HH:mm')}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }
                                    else {
                                        return (
                                            <div key={id} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', marginTop: '0.5rem', marginBottom: '0.5rem' }}>
                                                <div style={{ padding: '5px', backgroundColor: 'azure', marginRight: '3rem', marginLeft: '0.5rem', borderRadius: '5px', textAlign: 'justify' }}>
                                                    <p style={{ marginBottom: 0, color: 'blue', fontWeight: 'bold', fontSize: '0.9rem' }}>{item.sender.name}</p>
                                                    <p style={{ marginBottom: 0, color: 'black',fontSize:14 }}>{item.message}</p>
                                                    <div style={{ display: 'flex' }}>
                                                        <p style={{ fontSize: 13, marginBottom: 0, fontSize: 10, marginRight: 3, marginLeft: '0.5rem' }}>{moment(item.created_at).tz("America/Sao_Paulo").format('DD/MM/YYYY HH:mm')}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }
                                })}
                            </div>

                            <div
                                style={{ height: '70px', backgroundColor: '#f2f2f2', borderTop: '1px solid lightgrey', padding: '5px', display: 'flex', borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px' }}>
                                <input autoComplete="off" onKeyPress={(e) => {
                                    let keyCode = e.keyCode || e.which;
                                    if (keyCode == 13) {
                                        if (this.state.aluno_select.id != 'comunicado') {
                                            if(this.props.send_message(this.state.texto)){
                                                this.setState({ texto: '' });
                                                setTimeout(() => {
                                                    this.messages_view.current.scrollTop = this.messages_view.current.scrollHeight;
                                                }, 100);
                                            }
                                        }
                                        else {
                                            this.send_comunicado();
                                        }
                                    }
                                }} onChange={(e) => this.setState({ texto: e.target.value })} value={this.state.texto} name="msg" placeholder="Mensagem..." className="form-control msg_input" style={{ resize: 'none', height: '100%' }} />
                                <div className="send_msg" onClick={() => {
                                    if (this.state.aluno_select.id != 'comunicado') {
                                        if(this.props.send_message(this.state.texto)){
                                            this.setState({ texto: '' });
                                            setTimeout(() => {
                                                this.messages_view.current.scrollTop = this.messages_view.current.scrollHeight;
                                            }, 100);
                                        }
                                    }
                                    else {
                                        this.send_comunicado();
                                    }
                                }}
                                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '5px',cursor:'pointer' }}>
                                    <i className="mdi mdi-send" style={{ fontSize: '1.4rem' }}></i>

                                </div>
                            </div>
                        </div>

                        <div hidden={this.state.tab != 1}>
                            <div ref={this.requests_view} style={{ height: window.innerHeight - 40  - 40, padding: 0, margin: 0 }} className="overflow-auto hide-scroll" id="body_requests">
                                {this.state.aluno_select?.requests?.map((item,id)=>{
                                   return( <div key={id} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', marginTop: '0.5rem', marginBottom: '0.5rem' }}>
                                    <div style={{ padding: '5px', backgroundColor: '#f2f2f2', marginRight: '0.5rem', marginLeft: '0.5rem', borderRadius: '5px', textAlign: 'justify',width:'100%' }}>
                                        <p style={{ marginBottom: 0, color: 'black', fontWeight: 'bold', fontSize: '0.9rem' }}>Solicitação de pausa</p>
                                        <p style={{ marginBottom: 0, color: 'black',fontSize:15 }}>{item.reason}</p>
                                        <p style={{ marginBottom: 0, color: 'black',fontSize:15 }}><b>Status:</b> {item.approved==null?<span style={{color:'darkgoldenrod'}}>-</span>:(item.approved==true?<span style={{color:'green'}}>Aprovada</span>:<span style={{color:'red'}}>Declinada</span>)}</p>
                                        {item.approved==true && <p style={{ marginBottom: 0, color: 'black',fontSize:15 }}><b>Iniciou em: </b> {item.started!=null?moment(item.started).format('DD/MM/YYYY HH:mm:ss'):'-'}  </p>}
                                        {item.approved==true && <p style={{ marginBottom: 0, color: 'black',fontSize:15 }}><b>Encerrou em: </b> {item.pause_end!=null?moment(item.pause_end).format('DD/MM/YYYY HH:mm:ss'):'-'}  </p>}
                                        {item.approved==false && <p style={{ marginBottom: 0, color: 'black',fontSize:15 }}><b>Negado por: </b> {item.justify}  </p>}

                                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <p style={{ fontSize: 13, marginBottom: 0, fontSize: 10, }}>{moment(item.created_at).tz("America/Sao_Paulo").format('DD/MM/YYYY HH:mm')}</p>
                                        </div>
                                        {id==this.state.aluno_select?.requests.length-1 && this.state.aluno_select?.requests[id].approved==null &&  <div className='d-flex mt-1'>
                                            {this.props.loadings_pause[this.state.aluno_select?.requests[id].id]!=true ? <>
                                        <button onClick={() => {
                                                this.props.mudarState({show_modal_request:true,request:{...item,index:id,type:'refused'}});
                                            }} className="btn btn-secondary btn-outline btn-circle m-r-5 w-50" ><i
                                                className="mdi mdi-close"
                                                style={{ fontSize: '15px', color: 'white', fontStyle: 'normal', paddingRight: '1rem' }}>
                                                    Recusar</i></button>
                                        <button onClick={() => {
                                                this.props.mudarState({show_modal_request:true,request:{...item,index:id,type:'accept'}});
                                                
                                            }} className="btn btn-success btn-outline btn-circle w-50" ><i
                                                className="mdi mdi-check"
                                                style={{ fontSize: '15px', color: 'white', fontStyle: 'normal', paddingRight: '1rem' }}>
                                                    Aprovar</i></button>
                                            </>:<div className="spinner-border text-primary" role="status"  >
                            <span className="sr-only">Loading...</span>
                        </div>}


                                        </div>}
                                      
                                    </div>
                                </div>)
                                })}
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        );
    }
}
const mapsStateToProps = (state) => (
    {
        tempo_atual: state.AppReducer.tempo_atual,
        processo: state.AppReducer.processo,
        sala: state.AppReducer.sala,
        token: state.AppReducer.token,
        user: state.AppReducer.user,
        socket: state.AppReducer.socket,
        faculdade: state.AppReducer.faculdade,
    }
);

export default withRouter(connect(mapsStateToProps, { logout, mudarMensagens, mudarChatOpen })(ChatIndividual));


