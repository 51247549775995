import { Box, Tooltip } from "@mui/material";
import React  from 'react';

export default function ControlButton({
  bgImage = null,
  icon = null,
  tooltip,
  onClick,
}) {
  return (
    <Tooltip
      title={
        <span
          style={{
            fontSize: "1rem",
            textAlign: "center",
          }}
        >
          {tooltip}
        </span>
      }
      placement="bottom"
      arrow
    >
      <Box
        className="Transition-3"
        sx={{
          height: "3.6rem",
          width: "3.6rem",
          borderRadius: "1rem",
          backgroundColor: "var(--background-color0)",
          border: "none",
          outline: "none",
          cursor: "pointer",
          fontSize: "1.6rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
          boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 2px 0px",
          color: "rgba(var(--accent-color1), 0.7)",
          border: "1px solid rgba(var(--accent-color1), 0.1)",
          "&:hover": {
            color: "rgba(var(--accent-color1), 1)",
            transform: "scale(1.1)",
            border: "1px solid rgba(var(--accent-color1), 0.3)",
            "&:active": {
              transform: "scale(1)",
              transition: "none",
            },
          },
        }}
        onClick={onClick}
      >
        {icon ? (
          icon
        ) : (
          <img
            src={bgImage}
            alt="User"
            style={{
              padding: "0.1rem",
              height: "100%",
              width: "100%",
              objectFit: "cover",
              borderRadius: "1rem",
            }}
          />
        )}
      </Box>
    </Tooltip>
  );
}
