
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { logout, mudarMensagens, mudarChatOpen } from '../actions/AppActions';
import { connect } from 'react-redux';

import moment from 'moment-timezone';
import { URL } from '../variables';

class ListStudents extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menu: false,
            redirect: false,
            path: '/',
            texto: '',
            chat_open: -1,
            alunos: {},
            aluno_select: { mensagens: [],requests:[] },
            comunicados: [],
            name_filter: '', tab: 0,
            loadings:{},
            show_buttons:{}
        };
        this.messages_view = React.createRef();
        this.requests_view = React.createRef();

    }

    



    componentDidMount() {

        
    }

    componentWillUnmount() {
        clearTimeout(this.logs_timeout);
    }

    register_presence(presence, aluno_id) {
        let loadings = {...this.state.loadings};
        loadings[aluno_id]=true;
        this.setState({loadings});
        fetch(`${URL}api/fiscal/register_presence`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                candidato_id:aluno_id,
                presence, processo_id:this.props.processo.id
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                else {
                    let alunos_m = { ...this.props.alunos_m };
                    if (alunos_m[aluno_id] != undefined) {
                        alunos_m[aluno_id].presence = resp.presence;
                        this.props.mudarState({ alunos_m });
                    }
                    let loadings = {...this.state.loadings};
                    loadings[aluno_id]=false;
                    let show_buttons ={...this.state.show_buttons};
                    show_buttons[aluno_id]=false
                    this.setState({loadings,show_buttons});
                }
            } catch (err) {
                console.log(err);
                let loadings = {...this.state.loadings};
                loadings[aluno_id]=false;
                this.setState({loadings});
            }

        })
            .catch((err) => {
                console.log(err);
                let loadings = {...this.state.loadings};
                loadings[aluno_id]=false;
                this.setState({loadings});
            });
    }




    uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    
    render() {
        let alunos = Object.values(this.props.alunos_m);
        alunos = alunos.sort(function (a, b) { return b.name - a.name })
        // console.log(this.state.aluno_select);
        return (
            <div
                style={{
                    background: 'white', height: '100vh', width: '100%',
                    bottom: this.props.list_open == false ? '-400px' : 0,
                    right: '15px',
                    borderRadius: '5px',
                    wordBreak: 'break-word',
                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
                }} id="chat_father">


                <div className="row">
                    <div className="col-12">
                        <div style={{
                            boxShadow: "rgba(0, 0, 0, 0.2) 0px 4px 8px 0px, rgba(0, 0, 0, 0.19) 0px 6px 20px 0px"
                        }}>

                        <a id="close_chat"

                            style={{ borderTopLeftRadius: '5px', borderTopRightRadius: '5px', backgroundColor: '#f2f2f2', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>

                            <p style={{ margin: 0,fontSize:14,fontWeight:'bold', color: 'black', textAlign: 'center' }}>Lista de Candidatos da Sala {this.props.sala?.numero}</p>
                            <button type="button" className="close" data-dismiss="alert" aria-label="Close" style={{
                                position: 'absolute',
                                left: '10px',
                                // color: 'white',
                                fontSize: '2rem'
                            }}
                                onClick={() => {
                                    this.props.setListOpen(false);
                                }}
                            >
                                <span aria-hidden="true"><i className="mdi mdi-close"></i></span>
                            </button>

                        </a>
                      
                        </div>

                        <div >
                            <div ref={this.requests_view} style={{ height: window.innerHeight - 40, padding: 0, margin: 0 }} className="overflow-auto hide-scroll" id="body_list">
                                {alunos?.map((item,id)=>{
                                   return( <div key={id} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', marginTop: '0.5rem', marginBottom: '0.5rem' }}>
                                    <div style={{ padding: '5px', backgroundColor: '#f2f2f2', marginRight: '0.5rem', marginLeft: '0.5rem', borderRadius: '5px', textAlign: 'justify',width:'100%' }}>
                                        <p style={{ marginBottom: 0, color: 'black', fontWeight: 'bold', fontSize: '0.9rem' }}>{item.name}</p>
                                        {/* <p style={{ marginBottom: 0, color: 'black',fontSize:15 }}>{item.reason}</p> */}
                                        <p style={{ marginBottom: 0, color: 'black',fontSize:15 }}><b>Status:</b> {this.props.number_online.includes(item?.id?.toString())?<span style={{color:'green'}}>Online</span>:<span style={{color:'red'}}>Offline</span>}</p>
                                        {<p style={{ marginBottom: 0, color: 'black',fontSize:15 }}><b>Saídas de tela: </b>  <span style={{ color: this.props.alunos_m[item.id].mensagens.filter((item2, id) => item2.warning == true && item2.message == 'O candidato saiu da página da avaliação').length > 0 ? 'darkgoldenrod' : 'black' }}>{this.props.alunos_m[item.id].mensagens.filter((item2, id) => item2.warning == true && item2.message == 'O candidato saiu da página da avaliação').length}</span>  </p>}
                                        <p onClick={(e)=>{
                                            let show_buttons ={...this.state.show_buttons};
                                            show_buttons[item.id]=!show_buttons[item.id];
                                            this.setState({show_buttons})
                                        }} style={{cursor:'pointer', marginBottom: 0, color: 'black',fontSize:15 }}><b>Presença:</b> {item.presence==null?(<span style={{color:'darkgoldenrod'}}>Não registrado</span>): (item.presence.presence? <span style={{color:'green'}}>Presente</span>:<span style={{color:'red'}}>Ausente</span>)} <i className='mdi mdi-arrow-right'></i></p>
                                       {this.state.show_buttons[item.id]==true &&  <>
                                        <div className='d-flex mt-1'>
                                            {this.state.loadings[item.id]!=true ? <>
                                        <button onClick={() => {
                                            this.register_presence(false,item.id)
                                            }} className="btn btn-danger btn-outline btn-circle m-r-5 w-50" ><i
                                                className="mdi mdi-close"
                                                style={{ fontSize: '15px', color: 'white', fontStyle: 'normal', paddingRight: '1rem' }}>
                                                    Marcar como Ausente</i></button>
                                        <button onClick={() => {
                                            this.register_presence(true,item.id)
                                            }} className="btn btn-success btn-outline btn-circle w-50" ><i
                                                className="mdi mdi-check"
                                                style={{ fontSize: '15px', color: 'white', fontStyle: 'normal', paddingRight: '1rem' }}>
                                                    Marcar como Presente</i></button>
                                            </>:<div className="spinner-border text-primary" role="status"  >
                            <span className="sr-only">Loading...</span>
                        </div>}


                                        </div></>}
                                        
                                    </div>
                                </div>)
                                })}
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        );
    }
}
const mapsStateToProps = (state) => (
    {
        tempo_atual: state.AppReducer.tempo_atual,
        processo: state.AppReducer.processo,
        sala: state.AppReducer.sala,
        token: state.AppReducer.token,
        user: state.AppReducer.user,
        socket: state.AppReducer.socket,
        faculdade: state.AppReducer.faculdade,
    }
);

export default withRouter(connect(mapsStateToProps, { logout })(ListStudents));


