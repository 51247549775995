import { URL } from "../variables"


const getRooms = async (processo_id,token,sala_id=null)=>{
    return fetch(`${URL}api/fiscal/get_salas/${processo_id}${sala_id==null?'':('/'+sala_id)}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',

            'Authorization': `Bearer ${token}`,
            // 'Content-Type': 'application/json',
        }
    });
}

const getRoom = async (processo_id,sala_id,token)=>{
    return fetch(`${URL}api/fiscal/get_sala/${processo_id}/${sala_id}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',

            'Authorization': `Bearer ${token}`,
            // 'Content-Type': 'application/json',
        }
    });
}

export {getRooms,getRoom};