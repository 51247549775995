import React from 'react';
import Header from './header';

import { URL } from '../variables';
import { connect } from 'react-redux';
import { mudarUser, logout } from '../actions/AppActions';
import { Redirect, Link } from 'react-router-dom';

import moment from 'moment-timezone';
import { Modal, Button } from 'react-bootstrap'
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { Form } from "react-bootstrap";

class SolicitacaoModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            msg: '',
            loading_screen: false,
            show_video: false,
            show_solicitar: true,
            show_aceitar: false,
            stream: null,
            redirect: false,
            path: '/sala',
            processos: [],
            candidato_id: '',
            time:300,
            loading: false,
            justify: '',
            msgErro: '',
            extend_end:false
        }
        this.videoRef = React.createRef();

        // this.videoRef=null;
    }
    componentDidMount() {
        // this.get_processos();

    }

    componentWillUnmount() {
        // clearTimeout(this.logs_timeout);
    }

    componentDidUpdate(props){
        if(this.props.show_modal_request==false && props.show_modal_request!=this.props.show_modal_request){
            this.setState({
                justify: '',
            msgErro: '',
            extend_end:false,
            })
        }
    }

    convertMinute(value){
        let label = '';
        if(value<60){
            label='30 segundos';
        }
        else{
            label=(parseInt(value/60)+' minuto(s)') + (Number.isInteger(value/60)?'':' e 30 segundos');
        }
        return label;
    }

    salvar(){
        if(this.props.request.type=='decline' && this.state.justify.length<1){
            this.setState({msgErro:'O campo de não pode ser vazio'});
            return;
        }
        else if(this.props.request.type=='accept' && this.state.time==''){
            this.setState({msgErro:'Preencha o tempo que o candidato poderá ficar em pausa'});
            return;
        }
        this.props.sendRequestResponse({
            approved:this.props.request.type=='accept'?true:false,
            justify:this.state.justify==''?null:this.state.justify,
            time:this.state.time==''?null:this.state.time,
            fiscal_id:this.props.user.id,
            id:this.props.request.id,
            name:this.props.candidato_select.name,
            sala_numero:this.props.sala.numero,
            extend_end:this.state.extend_end,
            sala_id:this.props.sala.id,
            aluno_id:this.props.request.user_id
        })

        
        
    }



    render() {
        // let sala_id = this.props.sala != '' ? this.props.sala.id : '';
        let candidato_select=this.props.candidato_select;
        // let candidatos = this.props.candidatos.filter((item, id) => {
        //     if (item.sala_id != sala_id) {
        //         return false;
        //     }
        //     return true;
        // });
        return (
            <>
                {/* <Button variant="primary" onClick={() => this.setState({ show_modal_action: true,candidato_id:'',acao:'' })}>
                    Reportar Ação Suspeita
                </Button> */}


                <Modal
                    show={this.props.show_modal_request}
                    onHide={() => this.props.setState({ show_modal_request: false })}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <span style={{color:'black'}}>Solicitação de pausa</span>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.msgErro != '' && <div>
                            <div className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>

                                <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>

                            </div>
                            <br />

                        </div>}
                        {candidato_select.id!=undefined && <div className="form-group row mb-0">
                            <div className="col-sm-12">
                                <p style={{color:'black',marginBottom:5,fontSize:15}}><b style={{marginRight: "2px"}}>Nome: </b>{candidato_select.name}</p>
                                <p style={{color:'black',marginBottom:5,fontSize:15}}><b style={{marginRight: "2px"}}>Sala: </b> Sala {this.props.sala.numero}</p>
                                <p style={{color:'black',marginBottom:5,fontSize:15,whiteSpace:'pre-line'}}><b >Justificativa: </b><br/> {this.props.request?.reason}</p>

                            </div>
                        </div>}
                        {this.props.request?.type=='accept' && <div className="form-group row">
                            <label className="col-sm-12 col-form-label" style={{fontSize:15}}><b>Tempo de pausa</b> <span style={{color: "red", fontWeight: "700"}}>*</span></label>
                            <div className="col-sm-12">
                                <select style={{fontSize:15}} value={this.state.time} className="custom-select" id="cameras" onChange={(e) => {
                                    this.setState({ time: e.target.value })
                                }}>
                                    <option value={''}>Selecione o tempo de pausa</option>

                                    {new Array(27).fill(1,0,27).map((item, id) => (
                                        <option key={id} value={(id+4)*30}>{this.convertMinute((id+4)*30)}</option>
                                    ))}
                                </select>
                            </div>
                            <label className="col-sm-12 col-form-label mt-2" style={{fontSize:15}}><b>Tempo de pausa será acrescentado no tempo de prova do candidato?</b>  <span style={{color: "red", fontWeight: "700"}}>*</span></label>
                            <div className="col-sm-12 ">
                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                name="radio-buttons-group0"
                                                value={this.state.extend_end}
                                                style={{flexDirection:'row',display:'flex'}}
                                                onChange={(e) => {
                                                }}>


                                                <FormControlLabel onClick={(e) => {
                                                    console.log(e.target.value);
                                                    this.setState({ extend_end: false });

                                                }} control={<Radio checked={this.state.extend_end == false} value={false} />} style={{ color: 'black',width:'fit-content',fontSize:15 }} label={'Não'} />
                                                <FormControlLabel onClick={(e) => {
                                                    console.log(e.target.value);
                                                    this.setState({ extend_end: true });
                                                }} control={<Radio checked={this.state.extend_end == true} value={true} />} style={{ color: 'black',width:'fit-content',fontSize:15 }} label={'Sim'} />
                                            </RadioGroup>
                            </div>

                        </div>}
                        {this.props.request?.type=='refused' && <div className="form-group row">
        
                            <label className="col-sm-12 col-form-label" style={{color:'black',fontSize:15}}><b>Explique porque a pausa está sendo declinada</b> <span style={{color: "red", fontWeight: "700"}}>*</span></label>
                            <div className="col-sm-12">
                                <textarea style={{fontSize:15}} className="form-control" value={this.state.justify} placeholder="Justificativa" type="text" onChange={(e) => this.setState({ justify: e.target.value })} />

                            </div>
                        </div>}
                    </Modal.Body>
                    {this.props.loadings_pause[this.props.request?.id] != true && <Modal.Footer>
                        <Button variant="secondary" className="btn-lg" onClick={() => this.props.setState({ show_modal_request: false })}>Fechar</Button>
                        <Button className="btn-lg" onClick={() => this.salvar()}>Enviar</Button>

                    </Modal.Footer>}
                    {this.props.loadings_pause[this.props.request?.id] == true && <Modal.Footer><div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                        <div className="spinner-border text-primary" role="status"  >
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div></div></Modal.Footer>}
                </Modal>

            </>


        );
    }


}

const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
        faculdade: state.AppReducer.faculdade,
        processo: state.AppReducer.processo,
        user: state.AppReducer.user,

    }
);

export default connect(mapsStateToProps, { mudarUser, logout })(SolicitacaoModal);
