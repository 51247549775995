import { Icon, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useState } from "react";
import React  from 'react';

const right_icon_theme = {
  color: "var(--text-color0)",
  fontSize: "1.8rem",
  position: "absolute",
  top: "50%",
  left: "0.8rem",
  transform: "translateY(-50%)",
};

const left_icon_theme = {
  color: "var(--text-color0)",
  fontSize: "1.8rem",
  position: "absolute",
  top: "50%",
  right: "0.8rem",
  transform: "translateY(-50%)",
};

const CustomInput = ({
  type = "text",
  placeholder = "",
  value,
  onChangeTarget = null,
  icon = null,
  reverse = null,
  required = false,
  // disabled = false,
  helperText = null,
  onChange = () => {},
}) => {
  const [helper_text_hover, set_helper_text_hover] = useState(false);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: reverse ? "row-reverse" : "row",
        gap: "0.5rem",
        position: "relative",
      }}
    >
      {icon && <Icon component={icon} sx={right_icon_theme} />}

      <input
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={
          onChangeTarget
            ? (e) => onChangeTarget(e)
            : (e) => onChange(e.target.value)
        }
        style={{
          paddingLeft: icon && !reverse ? "3.2rem" : "1rem",
          paddingRight: icon && reverse ? "3.2rem" : "1rem",
        }}
      />

      <InfoOutlinedIcon
        sx={{
          ...left_icon_theme,
          fontSize: "1.4rem",
        }}
        onMouseEnter={() => set_helper_text_hover(true)}
        onMouseLeave={() => set_helper_text_hover(false)}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "0.5rem 1rem",
          borderRadius: "0.4rem",
          backgroundColor: "var(--background-color2)",
          position: "absolute",
          top: "1.8rem",
          right: "1.8rem",
          maxWidth: "20rem",
          fontSize: "0.9rem",
          gap: "0.5rem",
          opacity: helper_text_hover ? 1 : 0,
          visibility: helper_text_hover ? "visible" : "hidden",
          transition: "all 0.2s ease-in-out",
          zIndex: 2,
        }}
        onMouseEnter={() => set_helper_text_hover(true)}
        onMouseLeave={() => set_helper_text_hover(false)}
      >
        {helperText && (
          <div style={{ color: "var(--text-color0)" }}>{helperText}</div>
        )}

        {required && (
          <div style={{ color: "var(--text-color2)", fontWeight: "bold" }}>
            Campo obrigatório.
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomInput;
